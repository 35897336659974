import React from 'react'
import ReactMarkdown from 'react-markdown'
import md from './PrivacyPolicy.md'
import './PrivacyPolicy.scss'

const PrivacyPolicy = () => (
  <div className="privacy-policy">
    <ReactMarkdown
      source={md}
    />
  </div>
)

export default PrivacyPolicy
