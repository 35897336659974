export default new Map([
  [7, {
    name: 'Test',
    logo: '/logos/ooca.png',
    email_placeholder: 'Please provide your email address',
    landing_page_url: 'https://www.ooca.co/',
  }],
  [10048, {
    name: 'PwC Thailand',
    logo: '/logos/ooca.png',
    email_placeholder: 'xxxxx@pwc.com',
    landing_page_url: 'https://www.ooca.co/',
  }],
  [10057, {
    name: 'KPMG',
    logo: '/logos/kpmg.png',
    email_placeholder: 'Please provide your email address',
    landing_page_url: 'https://www.ooca.co/',
  }],
  [10041, {
    name: 'KASIKORN',
    logo: '/logos/ooca.png',
    email_placeholder: 'Please provide your email address',
    landing_page_url: 'https://www.ooca.co/',
  }],
  [10018, {
    name: 'TEST (Staging)',
    logo: '/logos/ooca.png',
    email_placeholder: 'Please provide your email address',
    landing_page_url: 'https://www.ooca.co/',
  }],
])